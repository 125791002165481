import React from 'react'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined'
import { Collapse } from '@mui/material'
import IconButton from '@mui/material/IconButton'
import ExpandLessIcon from '@mui/icons-material/ExpandLess'
import Popover from '@mui/material/Popover'
import Picker from '@emoji-mart/react'
import ButtonBase from '@mui/material/ButtonBase'
import { Row, Col } from 'react-bootstrap'
import './ActivityTypeDropdown.css'

export function ActivityTypeDropdown (props) {
  const [open, setOpen] = React.useState(true)
  const [popoverState, setPopOverState] = React.useState(false)
  const [anchorEl, setAnchorEl] = React.useState(null)
  const [emoji, setEmoji] = React.useState(props.emoji.replace('thumbs/120', 'thumbs/60'))
  const handleEmojiClick = (event) => {
    setAnchorEl(event.currentTarget)
    setPopOverState(true)
  }

  const handleEmojiPickerSelect = (emoji) => {
    const emojiName = emoji.name.toLowerCase().replaceAll(' ', '_').replaceAll('_', '-')
    const emojipediaThumbnail = `https://em-content.zobj.net/thumbs/60/apple/325/${emojiName}_${emoji.unified}.png`
    setEmoji(emojipediaThumbnail)
    if (props.emojiChangeCallback) {
      props.emojiChangeCallback(emojipediaThumbnail)
    }
  }
  return (
    <>
      <div className='likedPage-activityTypeDropdown'>
        <h3 className='likedPage-activityTypeDropdownTitle'>
          <span onClick={props.handleCategoryNameClick}>
            {props.name + ' '}
          </span>
          {props.allowEmojiEditing
            ? (
              <>
                <ButtonBase onClick={handleEmojiClick} className='likedPage-activityTypeDropdownEmojiButtonBase'>
                  <img alt='dropdown emoji' className='likedPage-activityTypeDropdownTitleEmoji' src={emoji} />
                </ButtonBase>
                <Popover
                  open={popoverState}
                  anchorEl={anchorEl}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left'
                  }}
                  onClose={() => setPopOverState(false)}
                >
                  <Picker
                    onEmojiSelect={handleEmojiPickerSelect}
                    emojiSize={24}
                    emojiButtonSize={28}
                  />
                </Popover>
              </>
              )
            : <img alt='dropdown emoji' className='likedPage-activityTypeDropdownTitleEmoji' src={emoji} />}
        </h3>
        <Row>
          {props.allowEmojiEditing
            ? (
              <Col className='likedPage-deleteIconContainer'>
                <IconButton onClick={() => props.onDeleteAction()}>
                  <DeleteOutlinedIcon className='likedPage-deleteIcon' alt='delete icon' />
                </IconButton>
              </Col>
              )
            : <></>}
          <Col className='likedPage-dropdownIconContainer'>
            <IconButton aria-label='dropdown' onClick={() => setOpen(!open)}>
              {open ? <ExpandLessIcon className='likedPage-dropdownIcon' alt='dropdown icon' /> : <ExpandMoreIcon className='likedPage-dropdownIcon' alt='dropdown icon' />}
            </IconButton>
          </Col>
        </Row>
      </div>
      <Collapse in={open}>
        {React.cloneElement(props.children)}
      </Collapse>
    </>
  )
}
