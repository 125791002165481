import { lazy } from 'react'
const ActivityCard = lazy(() => import('./ActivityCard/ActivityCard'))
const Loading = lazy(() => import('./Loading/Loading'))
const LocationPin = lazy(() => import('./LocationPin/LocationPin'))
const Map = lazy(() => import('./Map/Map'))
const NavBar = lazy(() => import('./NavBar/NavBar'))
const PrivateRoute = lazy(() => import('./PrivateRoute/PrivateRoute'))
const SearchInput = lazy(() => import('./SearchInput/SearchInput'))
const YelpAutocomplete = lazy(() => import('./YelpAutocomplete/YelpAutocomplete'))
const MapSearchResultModal = lazy(() => import('./MapSearchResultModal/MapSearchResultModal'))
const GuideCard = lazy(() => import('./GuideCard/GuideCard'))
const ImageEditor = lazy(() => import('./ImageEditor/ImageEditor'))
const SimplePageBackdrop = lazy(() => import('./SimplePageBackdrop/SimplePageBackdrop'))
const ActivityCardSwipeable = lazy(() => import('./ActivityCardSwipeable/ActivityCardSwipeable'))
const ActivityCardFull = lazy(() => import('./ActivityCardFull/ActivityCardFull'))
const ActivityCardMap = lazy(() => import('./ActivityCardMap/ActivityCardMap'))
const ActivityTypeDropdown = lazy(() => import('./ActivityTypeDropdown/ActivityTypeDropdown'))
const FilterChipRow = lazy(() => import('./FilterChipRow/FilterChipRow'))

export {
  ActivityCard, Loading, LocationPin, Map, NavBar,
  PrivateRoute, SearchInput,
  YelpAutocomplete, MapSearchResultModal, GuideCard, ImageEditor, SimplePageBackdrop,
  ActivityCardFull,
  ActivityCardSwipeable,
  ActivityCardMap,
  ActivityTypeDropdown,
  FilterChipRow
}
