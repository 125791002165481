import { lazy } from 'react'
import Explore from '../pages/Explore/Explore'
import Guide from '../pages/Guide/Guide'
const About = lazy(() => import('../pages/About/About'))
const Stats = lazy(() => import('../pages/Stats/Stats'))
const CreateGuide = lazy(() => import('../pages/CreateGuide/CreateGuide'))
const Profile = lazy(() => import('../pages/Profile/Profile'))
const LikedPage = lazy(() => import('../pages/Liked/Liked'))
const Login = lazy(() => import('../pages/Login/Login'))
const NotFound = lazy(() => import('../pages/NotFound/NotFound'))
const Privacy = lazy(() => import('../pages/Privacy/Privacy'))
const Me = lazy(() => import('../pages/Me/Me'))
const Map = lazy(() => import('../pages/Map/Map'))
const EditPage = lazy(() => import('../pages/EditPage/EditPage'))
const Analytics = lazy(() => import('../pages/Analytics/Analytics'))

export { About, Stats, CreateGuide, Profile, Explore, LikedPage, Login, NotFound, Privacy, Me, Map, EditPage, Guide, Analytics }
