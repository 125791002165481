import api from '../../api'

import React, { Component, useEffect, useState } from 'react'
import { Container, Row, Col, Stack } from 'react-bootstrap'
import { GuideCard } from '../../components'
import { useNavigate } from 'react-router-dom'
// import NavBar from '../components/NavBar/NavBar'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import './Explore.css'
import { Avatar, Button, IconButton } from '@mui/material'
import AddIcon from '@mui/icons-material/Add'
import { Header } from '../../components/Header/Header'

const CITIES = [
  { name: 'Silicon Valley', query: { lat: 37.4275, lng: -122.1697 } },
  { name: 'San Francisco', query: { lat: 37.7749, lng: -122.4194 } },
  { name: 'New York City', query: { lat: 40.7128, lng: -74.0060 } },
  { name: 'Los Angeles', query: { lat: 34.0522, lng: -118.2437 } },
  { name: 'Houston', query: { lat: 29.7604, lng: -95.3698 } },
  { name: 'Chicago', query: { lat: 41.8781, lng: -87.6298 } }
]

export function LoginOrAvatar (props) {
  const navigate = useNavigate()

  if ((props.auth.isAuthenticated)) {
    return (
      <Stack direction='horizontal' style={{ flexDirection: 'row-reverse' }}>
        <Avatar
          src={`${props.auth.user.avatar}_small`}
          onClick={() => navigate(`../user/${props.auth.user.user_name}`)}
        />
        <IconButton className='explorePage-plusIconButton' onClick={() => navigate('../create')}>
          <AddIcon className='explorePage-plusIcon' />
        </IconButton>
      </Stack>
    )
  } else {
    return <Button variant='filled' className='explorePage-loginButton' onClick={() => navigate('../login')}>Login</Button>
  }
}

function ExploreCity (props) {
  const [guides, setGuides] = useState([{}, {}, {}])

  useEffect(() => {
    const fetchData = async () => {
      const res = await api.getGuidesByLocation(props.query)
      return res.data.data
    }

    fetchData()
      .then(guides => setGuides(guides))
      .catch(console.error)
  }, [props.query])

  return (
    <Row style={{ marginTop: '15px', marginBottom: '15px' }}>
      <Col>
        <h3 className='explorePage-cityTitle'>{props.name}</h3>
        <div className='flex-row flex-nowrap overflow-auto explore-scrollable-row'>
          {guides.map((guide, i) => {
            return <GuideCard key={i} guide={guide} />
          })}
        </div>
      </Col>
    </Row>
  )
}

class Explore extends Component {
  constructor (props) {
    super(props)

    this.state = {
    }
  }

  componentDidMount = async () => {
    document.title = 'Explore'
  }

  render () {
    return (
      <Container fluid className='explorePage-container'>
        <Header {...this.props} title='Explore' subtitle='Browse popular guides by city.' showAvatar />

        {CITIES.map((city) => {
          return <ExploreCity key={city.name} name={city.name} query={city.query} />
        })}
      </Container>
    )
  }
}

Explore.propTypes = {
  auth: PropTypes.object.isRequired
}

const mapStateToProps = state => ({
  auth: state.auth
})

export default connect(mapStateToProps)(Explore)
