import React from 'react'
import App from './app'
import { Provider } from 'react-redux'
import store from './store'
import { hydrate, render } from 'react-dom'

const rootElement = document.getElementById('root')
if (rootElement.hasChildNodes()) {
  hydrate(
    <Provider store={store}><App /></Provider>
    , rootElement)
} else {
  render(
    <Provider store={store}><App /></Provider>
    , rootElement)
}
