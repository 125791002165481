import AccessTimeFilledIcon from '@mui/icons-material/AccessTimeFilled'
import AccessTimeOutlinedIcon from '@mui/icons-material/AccessTimeOutlined'
import EditIcon from '@mui/icons-material/Edit'
import AssessmentIcon from '@mui/icons-material/Assessment'
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined'
import MapIcon from '@mui/icons-material/Map'
import ShareIcon from '@mui/icons-material/Share'
import { connect } from 'react-redux'
import Drawer from '@mui/material/Drawer'
import React from 'react'
import api from '../../api'
import { ActivityCard, NavBar, FilterChipRow } from '../../components'
import './Guide.css'
// import BookmarkIcon from '@mui/icons-material/Bookmark'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import AttachMoneyOutlinedIcon from '@mui/icons-material/AttachMoneyOutlined'
import LocationOnIcon from '@mui/icons-material/LocationOn'
import CircularProgress from '@mui/material/CircularProgress'
import { ApplicationInsights } from '@microsoft/applicationinsights-web'
import { ReactPlugin, withAITracking } from '@microsoft/applicationinsights-react-js'

import { Stack, Col, Container, Row } from 'react-bootstrap'

import { ActivityTypeDropdown } from '../../components/ActivityTypeDropdown/ActivityTypeDropdown'

import Avatar from '@mui/material/Avatar'
import CardHeader from '@mui/material/CardHeader'

import { useNavigate } from 'react-router-dom'
import Helmet from 'react-helmet'
import { Button, IconButton } from '@mui/material'

const reactPlugin = new ReactPlugin()
const appInsights = new ApplicationInsights({
  config: {
    connectionString: 'InstrumentationKey=848721ea-6f6b-4fb8-a82d-5d591a0ff81b;IngestionEndpoint=https://westus-0.in.applicationinsights.azure.com/;LiveEndpoint=https://westus.livediagnostics.monitor.azure.com/',
    extensions: [reactPlugin],
    enableAutoRouteTracking: true
  }
})
appInsights.loadAppInsights()
appInsights.trackPageView()

const MapPage = React.lazy(() => import('../Map/Map'))

function getDistanceFromLatLonInMi (lat1, lon1, lat2, lon2) {
  const R = 6371 // Radius of the earth in km
  const dLat = deg2rad(lat2 - lat1) // deg2rad below
  const dLon = deg2rad(lon2 - lon1)
  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(deg2rad(lat1)) * Math.cos(deg2rad(lat2)) *
    Math.sin(dLon / 2) * Math.sin(dLon / 2)

  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a))
  const d = R * c // Distance in km
  return d * 0.621371
}

function deg2rad (deg) {
  return deg * (Math.PI / 180)
}

function ActivityCardList (props) {
  return (
    <div className='likedPage-activityCardList'>
      {props.activitiesData.map((activityData, i) => {
        return (
          <ActivityCard {...activityData} key={i} />
        )
      })}
    </div>
  )
}

function ActivityCardLists (props) {
  return (
    <div className={window.navigator.standalone ? 'standalone likedPage-activityCardLists' : 'likedPage-activityCardLists'}>
      {props.categoriesData.map((data, i) => {
        return (
          <ActivityTypeDropdown key={i} name={data.name} emoji={data.custom_pin}>
            <ActivityCardList activitiesData={data.activities} />
          </ActivityTypeDropdown>
        )
      })}
    </div>
  )
}

function filterToOpenNow (guideData) {
  const currentDay = new Date().getDay()
  const currentHour = new Date().getHours()
  const currentMinute = new Date().getMinutes()
  const currentTime = currentHour * 100 + currentMinute
  const newGuideData = guideData
  for (let i = 0; i < guideData.categories.length; i++) {
    for (let j = 0; j < guideData.categories[i].activities.length; j++) {
      if (guideData.categories[i].activities[j].hours[0] !== undefined && guideData.categories[i].activities[j].hours[0].open !== undefined && guideData.categories[i].activities[j].hours[0].open[currentDay] !== undefined) {
        const startTime = guideData.categories[i].activities[j].hours[0].open[currentDay].start
        const endTime = guideData.categories[i].activities[j].hours[0].open[currentDay].end
        if (startTime > currentTime || endTime < currentTime) {
          newGuideData.categories[i].activities.splice(j, 1)
          j--
        }
      }
    }
  }
  return JSON.stringify(newGuideData)
}

function filterNearby (guideData, guideDataSetter, setLoadingStateNearby) {
  console.log('Getting position')
  const startTime = new Date()
  navigator.geolocation.getCurrentPosition((position) => {
    setTimeout(() => {
      const userLocation = position.coords
      const newGuideData = guideData
      for (let i = 0; i < guideData.categories.length; i++) {
        for (let j = 0; j < guideData.categories[i].activities.length; j++) {
          const activityCoordinates = guideData.categories[i].activities[j].coordinates
          const distance = getDistanceFromLatLonInMi(userLocation.latitude, userLocation.longitude, activityCoordinates.latitude, activityCoordinates.longitude)
          if (distance > 1) {
            newGuideData.categories[i].activities.splice(j, 1)
            j--
          }
        }
      }
      guideDataSetter(JSON.stringify(newGuideData))
      setLoadingStateNearby()
    }, Math.max(300 - (new Date() - startTime), 0))
  }, (error) => {
    console.log('ERROR', error)
    setLoadingStateNearby()
  }, { maximumAge: 60000, timeout: 5000, enableHighAccuracy: true }
  )
}

const applyFilters = (guideData, filters, guideDataSetter, setLoadingStateNearby) => {
  let newGuideData = JSON.parse(JSON.stringify(guideData))
  if (filters.includes('openNow')) {
    newGuideData = JSON.parse(filterToOpenNow(newGuideData))
  }
  // Dollar sign filters
  const dollarSignFilters = []
  for (let i = 0; i < filters.length; i++) {
    if (filters[i].includes('$')) {
      dollarSignFilters.push(filters[i])
    }
  }
  if (dollarSignFilters.length > 0) {
    newGuideData = JSON.parse(filterToPrice(newGuideData, dollarSignFilters))
  }
  if (filters.includes('nearby')) {
    filterNearby(newGuideData, guideDataSetter, setLoadingStateNearby)
  }
  guideDataSetter(JSON.stringify(newGuideData))
}

function filterToPrice (guideData, permittedPricesInDollarSigns) {
  const newGuideData = guideData
  for (let i = 0; i < guideData.categories.length; i++) {
    for (let j = 0; j < guideData.categories[i].activities.length; j++) {
      if (guideData.categories[i].activities[j].price !== undefined) {
        if (!permittedPricesInDollarSigns.includes(guideData.categories[i].activities[j].price)) {
          newGuideData.categories[i].activities.splice(j, 1)
          j--
        }
      }
    }
  }
  return JSON.stringify(newGuideData)
}

function LikedPage (props) {
  const navigate = useNavigate()
  const [guideData, setGuideData] = React.useState('')
  // const [focusedActivity, setFocusedActivity] = React.useState({})
  const [unfilteredData, setUnfilteredData] = React.useState('')
  const [drawerState, setDrawerState] = React.useState(props.showMap)
  const [filters, setFilters] = React.useState([])
  const [chipStates, setChipStates] = React.useState('')

  // TODO: compute isOwned based on user auth
  async function getData () {
    const response = await api.getGuideById(props.guideId ? props.guideId : window.location.pathname.split('/')[2])
    const guideObject = response.data.data
    const guideObjectString = JSON.stringify(guideObject)
    setGuideData(guideObjectString)
    setUnfilteredData(guideObjectString)
    // COLORS
    // setColors(guideObject)
    document.title = guideObject.nickname

    console.log('DATA', response.data.data)
  }
  if (guideData === '') {
    getData()
  }
  if (!guideData) {
    const funStatmements = [
      'Loading...'
    ]
    return (
      <div className='likedPage-loadingContainer'>
        <CircularProgress className='likedPage-loader' />
        <h5 className='likedPage-loadingText'>{funStatmements[Math.floor(Math.random() * funStatmements.length)]}</h5>
        {props.hideNavbar ? <></> : <NavBar />}
      </div>
    )
  }
  const guideObject = JSON.parse(guideData)
  const chips = [
    {
      label: 'Open Now',
      unfilledIcon: <AccessTimeOutlinedIcon />,
      filledIcon: <AccessTimeFilledIcon className='filterChipRow-filledIcon' />,
      handleFilterFunction: (currentChipStates, setChipStates, chipIndex) => {
        filters.push('openNow')
        setFilters(filters)
        applyFilters(guideObject, filters, setGuideData)
      },
      handleUnfilterFunction: (setChipStates, chipIndex) => {
        filters.splice(filters.indexOf('openNow'), 1)
        setFilters(filters)
        applyFilters(JSON.parse(unfilteredData), filters, setGuideData)
      }
    },
    {
      label: 'Nearby',
      loadingLabel: 'Getting your location...',
      unfilledIcon: <LocationOnOutlinedIcon />,
      filledIcon: <LocationOnIcon className='filterChipRow-filledIcon' />,
      loadingIcon: <CircularProgress className='filterChipRow-loadingIcon' size={18} />,
      handleFilterFunction: (currentChipStates, setChipStates, chipIndex) => {
        console.log('handling filter')
        const setLoadingStateNearby = () => {
          const newChipStates = currentChipStates
          newChipStates[chipIndex] = 'selected'
          setChipStates(JSON.stringify(newChipStates))
        }
        filters.push('nearby')
        setFilters(filters)
        applyFilters(guideObject, filters, setGuideData, setLoadingStateNearby)
      },
      handleUnfilterFunction: (currentChipStates, setChipStates, chipIndex) => {
        filters.splice(filters.indexOf('nearby'), 1)
        setFilters(filters)
        applyFilters(JSON.parse(unfilteredData), filters, setGuideData)
        const newChipStates = currentChipStates
        newChipStates[chipIndex] = 'unselected'
        setChipStates(JSON.stringify(newChipStates))
      }
    },
    {
      label: '$',
      unfilledIcon: <AttachMoneyOutlinedIcon />,
      filledIcon: <AttachMoneyOutlinedIcon className='filterChipRow-filledIcon' />,
      handleFilterFunction: (currentChipStates, setChipStates, chipIndex) => {
        filters.push('$')
        setFilters(filters)
        applyFilters(guideObject, filters, setGuideData)
      },
      handleUnfilterFunction: (setChipStates, chipIndex) => {
        filters.splice(filters.indexOf('$'), 1)
        setFilters(filters)
        applyFilters(JSON.parse(unfilteredData), filters, setGuideData)
      }
    },
    {
      label: '$$',
      unfilledIcon: <AttachMoneyOutlinedIcon />,
      filledIcon: <AttachMoneyOutlinedIcon className='filterChipRow-filledIcon' />,
      handleFilterFunction: (currentChipStates, setChipStates, chipIndex) => {
        filters.push('$$')
        setFilters(filters)
        applyFilters(JSON.parse(unfilteredData), filters, setGuideData)
      },
      handleUnfilterFunction: (setChipStates, chipIndex) => {
        filters.splice(filters.indexOf('$$'), 1)
        setFilters(filters)
        applyFilters(JSON.parse(unfilteredData), filters, setGuideData)
      }
    },
    {
      label: '$$$',
      unfilledIcon: <AttachMoneyOutlinedIcon />,
      filledIcon: <AttachMoneyOutlinedIcon className='filterChipRow-filledIcon' />,
      handleFilterFunction: (currentChipStates, setChipStates, chipIndex) => {
        filters.push('$$$')
        setFilters(filters)
        applyFilters(JSON.parse(unfilteredData), filters, setGuideData)
      },
      handleUnfilterFunction: (setChipStates, chipIndex) => {
        filters.splice(filters.indexOf('$$$'), 1)
        setFilters(filters)
        applyFilters(JSON.parse(unfilteredData), filters, setGuideData)
      }
    },
    {
      label: '$$$$',
      unfilledIcon: <AttachMoneyOutlinedIcon />,
      filledIcon: <AttachMoneyOutlinedIcon className='filterChipRow-filledIcon' />,
      handleFilterFunction: (currentChipStates, setChipStates, chipIndex) => {
        filters.push('$$$$')
        setFilters(filters)
        applyFilters(JSON.parse(unfilteredData), filters, setGuideData)
      },
      handleUnfilterFunction: (setChipStates, chipIndex) => {
        filters.splice(filters.indexOf('$$$$'), 1)
        setFilters(filters)
        applyFilters(JSON.parse(unfilteredData), filters, setGuideData)
      }
    }
  ]
  if (chipStates === '') {
    setChipStates(JSON.stringify(chips.map((chip) => { return 'unselected' })))
  }
  return (
    <>
      <Helmet>
        <title>{guideObject.nickname}</title>
        <meta name='title' content={guideObject.nickname} />
        <meta name='description' content={'Take a look at ' + guideObject.nickname} />

        <meta property='og:type' content='website' />
        <meta property='og:url' content='https://guides.rockhopper.app' />
        <meta property='og:title' content={guideObject.nickname} />
        <meta property='og:description' content={'Take a look at ' + guideObject.nickname} />
        <meta property='og:image' content={guideObject.thumbnail} />

        <meta property='twitter:card' content='summary_large_image' />
        <meta property='twitter:url' content='https://guides.rockhopper.app' />
        <meta property='twitter:title' content={guideObject.nickname} />
        <meta property='twitter:description' content={'Take a look at ' + guideObject.nickname} />
        <meta property='twitter:image' content={guideObject.thumbnail} />
      </Helmet>
      <Drawer
        anchor='right'
        open={drawerState}
        className='likedPage-mapDrawerContainer'
      >
        <MapPage guide={guideObject} close={props.showMap ? () => navigate(-1) : () => setDrawerState(false)} chips={chips} chipStates={chipStates} setChipStates={setChipStates} />
      </Drawer>

      <Container fluid id='guide-page'>
        <Row className='guide-header'>
          <Col>

            <div style={{ position: 'relative', padding: '0px', width: '33px', marginTop: '15px' }}>
              <ChevronLeftIcon className='guide-backarrowIcon' onClick={() => navigate('/explore')} />
            </div>
            {/* THUMBNAIL */}
            <Row style={{marginTop: "-20px", marginBottom: "20px"}}>
              <Col align='center'>
                {/* lift w box shadow */}
                <img className='guide-thumbnail' alt={`${guideObject.destination.name} thumbnail`} src={`${guideObject.thumbnail}_medium`} />
              </Col>
            </Row>

            {/* HEADER */}
            <Row>
              <Col>
                <h2 className='likedPage-cityText'>{guideObject.destination.name.toUpperCase()}</h2>
                <div className='row likedPage-titleEditAndMapContainer'>
                  <div className='column likedPage-titleAndEditContainer'>
                    <h1 className='likedPage-title'>{guideObject.nickname}</h1>
                    <div className='likedPage-cardHeaderAndEditContainer'>
                      <Stack direction='horizontal'>
                        <CardHeader
                          onClick={() => navigate(`../../user/${guideObject.creator.user_name}`)}
                          style={{ padding: '0' }}
                          avatar={<Avatar
                            style={{ marginRight: '-5px' }}
                            alt={guideObject.creator.user_name}
                            src={`${guideObject.creator.avatar}_small`}
                                  />}
                          title={guideObject.creator.nickname}
                        />
                        {
                        guideObject.creator.user_name === props.auth.user.user_name && (
                          <>
                            <IconButton className='likedPage-editIconButton'>
                              <EditIcon className='likedPage-editIcon' onClick={() => navigate(`/edit/${guideObject._id}`, { state: { guideObject } })} />
                            </IconButton>
                            <IconButton className='likedPage-analyticsIconButton'>
                              <AssessmentIcon className='likedPage-analyticsIcon' onClick={() => navigate(`/guide/${guideObject._id}/analytics`, { state: { guideObject } })} />
                            </IconButton>
                          </>
                        )
                      }
                      </Stack>
                    </div>
                  </div>

                </div>
              </Col>
            </Row>

            {/* CLOSE COLOR GRADIENT */}
          </Col>
        </Row>
        <Row className='guidePage-mapButtonRow'>
          <div align='right' className='guidePage-mapButtonCol'>
            <Button
              variant='contained' startIcon={<MapIcon />} className='guidePage-mapButton' onClick={() => {
                setDrawerState(true)
              }}
            >
              View Map
            </Button>
          </div>
          {navigator.share && (
            <div align='right' className='guidePage-mapButtonCol'>
              <Button
                variant='outlined' startIcon={<ShareIcon />} className='guidePage-shareButton' onClick={() => {
                  navigator.share({
                    title: 'Rockhopper',
                    text: 'Check out this guide I made on Rockhopper!',
                    url: window.location.href
                  })
                }}
              >
                Share Guide
              </Button>
            </div>
          )}
        </Row>
        {/* FILTER CHIPS */}
        {/* <Row>
          <Col> */}
        <p className='guidePage-filterChipRowTitle'>Filters:</p>
        <FilterChipRow chips={chips} chipStates={chipStates} setChipStates={setChipStates} />
        {/* </Col>
        </Row> */}
        <ActivityCardLists categoriesData={guideObject.categories} />
      </Container>

      {/* <ActivityCardModal openactivity={this.props.map_list[this.state.show_index].activity} /> */}
      {props.hideNavbar ? <></> : <NavBar />}
    </>
  )
}

const mapStateToProps = state => ({
  auth: state.auth
})

export default withAITracking(reactPlugin, connect(mapStateToProps)(React.memo(LikedPage)))
