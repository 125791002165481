import React, { Suspense } from 'react'
import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom'
import { createTheme, ThemeProvider } from '@mui/material/styles'

import './custom.scss'

import { Helmet } from 'react-helmet'

import { About, Stats, CreateGuide, Profile, Explore, LikedPage, Login, NotFound, Privacy, Me, EditPage, Guide, Analytics } from '../pages'

const theme = createTheme({
  palette: {
    primary: {
      main: '#6f12e9'
    },
    black: {
      main: '#000'
    }
  },
  typography: {
    fontFamily: '"Public Sans", sans-serif'
  },
  alignItems: 'center'
})

function App () {
  return (
    <>
      <Helmet>
        <title>Rockhopper</title>
        <meta name='title' content='Rockhopper' />
        <meta name='description' content='Simplifying experience planning.' />
        <meta property='og:type' content='website' />
        <meta property='og:url' content='https://guides.rockhopper.app/' />
        <meta property='og:title' content='Rockhopper' />
        <meta property='og:description' content='Simplifying experience planning.' />
        <meta property='og:image' content='https://guides.rockhopper.app/meta-splash.png' />
        <meta property='twitter:card' content='summary_large_image' />
        <meta property='twitter:url' content='https://guides.rockhopper.app/' />
        <meta property='twitter:title' content='Rockhopper' />
        <meta property='twitter:description' content='Simplifying experience planning.' />
        <meta property='twitter:image' content='https://guides.rockhopper.app/meta-splash.png' />
      </Helmet>
      <ThemeProvider theme={theme}>
        <BrowserRouter>
          <Suspense fallback={<div />}>

            <Routes>
              {/* BASE */}
              <Route exact path='/' element={<Navigate to='/explore' />} />

              {/* ABOUT */}
              <Route path='/about' element={<About />} />

              {/* STATS */}
              <Route path='/stats' element={<Stats />} />

              {/* CREATE GUIDE */}
              <Route path='/create' element={<CreateGuide />} />

              {/* ME */}
              <Route path='/me' element={<Me />} />

              {/* PROFILE */}
              <Route exact path='/user/:user_name' element={<Profile />} />

              {/* EXPLORE GUIDES */}
              <Route path='/explore' element={<Explore />} />

              {/* PRIVACY */}
              <Route path='/privacy' element={<Privacy />} />

              {/* GUIDE */}
              <Route exact path='/guide/:id' element={<Guide key='/guide/' hideNavbar />} />

              {/* EDIT PAGE */}
              <Route exact path='/edit/'>
                <Route path=':id' element={<EditPage />} />
                <Route path='' element={<EditPage />} />
              </Route>

              {/* ANALYTICS */}
              <Route exact path='/guide/:id/analytics' element={<Analytics />} />

              {/* MAP */}
              <Route exact path='/map' element={<Guide key='/map' showMap guideId='62be826659fe34c930e9094e' />} />

              {/* LIKED */}
              <Route exact path='/liked' element={<LikedPage key='/liked' guideId='62be826659fe34c930e9094e' />} />

              {/* LOGIN */}
              <Route path='/login' element={<Login />} />

              {/* NOT FOUND */}
              <Route path='*' element={<NotFound />} />

            </Routes>
          </Suspense>
        </BrowserRouter>
      </ThemeProvider>
    </>
  )
}

export default App
