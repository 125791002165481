import { configureStore } from '@reduxjs/toolkit'
import { authSlice } from './slices/authSlice'
import { viewItinerarySlice } from './slices/viewItinerarySlice'

export default configureStore({
  reducer: {
    auth: authSlice.reducer,
    viewItinerary: viewItinerarySlice.reducer
  }
})
