import axios from 'axios'

const apiURL = process.env.REACT_APP_API_URL || 'http://localhost:5000/api'
console.log('THE APP IS BEING SERVED ON ', apiURL)


const api = axios.create({
  baseURL: apiURL
})

// ADD API AUTH HEADERS
export const setAuthToken = (token) => {
  if (token) {
    // Apply authorization token to every request if logged in
    api.defaults.headers.common.Authorization = token
  } else {
    // Delete auth header
    delete api.defaults.headers.common.Authorization
  }
}

const token = localStorage.getItem('jwtToken')
setAuthToken(token)


// SEARCH
export const searchYelp = (term, lat, long) => api.get('/search/keyword', {
  params: {
    term,
    lat,
    long
  }
})

export const getYelpBusinessDetails = (business_id) => api.get('/search/business-id', {
  params: {
    id: business_id
  }
})

export const getYelpReviews = (business_id) => api.get('/yelp-reviews', {
  params: {
    id: business_id
  }
})

// ACTIVITY
export const getAllActivities = () => api.get('/activities')
export const createActivityById = id => api.post(`/activity/${id}`)
export const updateActivityById = (id, payload) => api.put(`/activity/${id}`, payload)
export const deleteActivityById = id => api.delete(`/activity/${id}`)
export const getActivityById = id => api.get(`/activity/${id}`)
export const getActivityByYelpId = id => api.get(`/activity-by-yelp-id/${id}`)
export const yelpAutocomplete = (query) => api.get('/yelp/autocomplete/', { params: query })

// USER
export const loginUser = payload => api.post('/user/login', payload)
export const registerUser = payload => api.post('/user/register', payload)
export const getAllUsers = () => api.get('/users')
export const updateUserById = (id, payload) => api.put(`/user/${id}`, payload)
export const deleteUserById = id => api.delete(`/user/${id}`)
export const getUserById = id => api.get(`/user/${id}`)

export const autocompleteUser = query => api.get(`/autocomplete/user/${query}`)

// GUIDE
export const createGuide = payload => api.post('/guide', payload)
export const checkGuideEntries = payload => api.post('/check-guide', payload)
export const getGuideById = id => api.get(`/guide/${id}`)
export const updateGuideById = (id, payload) => api.put(`/guide/${id}`, payload)
export const getGuidesByUsername = user_name => api.get(`username/guide/${user_name}`)
export const getGuidesByLocation = (query) => api.get('/location/guide', { params: query })
export const getGuideViewsByGuide = id => api.get(`/guide/${id}/views`)
export const getGuideViewChart = id => api.get(`/guide/${id}/chart`)

export const getDestinationDetails = (query) => api.get('/location', { params: query })

// IMAGE
export const uploadImage = payload => api.post('/image/upload', payload)

const apis = {
  setAuthToken,

  searchYelp,
  getYelpBusinessDetails,
  getYelpReviews,

  getAllActivities,
  updateActivityById,
  deleteActivityById,
  getActivityById,
  yelpAutocomplete,
  getActivityByYelpId,
  createActivityById,

  loginUser,
  registerUser,
  getAllUsers,
  updateUserById,
  deleteUserById,
  getUserById,
  autocompleteUser,

  createGuide,
  getGuideById,
  updateGuideById,
  checkGuideEntries,
  getGuidesByUsername,
  getGuidesByLocation,
  getDestinationDetails,
  getGuideViewsByGuide,
  getGuideViewChart,

  uploadImage
}

export default apis
