import api from '../api'
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import jwt_decode from 'jwt-decode'

// GET CURRENT USER WITH TOKEN AND MOVE TO INITIAL STATE
const token = localStorage.getItem('jwtToken')
const decoded = token ? jwt_decode(token) : {}

// Check for expired token
const currentTime = Date.now() / 1000 // to get in milliseconds
if (decoded.exp < currentTime) {
  // Logout user
  localStorage.removeItem('jwtToken')
  api.setAuthToken(false)

  // Redirect to login
  window.location.href = './login'
}

const initialState = {
  isAuthenticated: !!token,
  user: decoded,
  google_info: {},
  loading: false,
  errors: {}
}

export const registerUser = createAsyncThunk(
  'auth/registerUser',
  async ({ userData, navigate }, thunkAPI) => {
    try {
      const res = await api.registerUser(userData)

      console.log(res)
      const { token } = res.data
      localStorage.setItem('jwtToken', token)
      navigate('../explore', { replace: true })
      // Decode token to get user data
      const decoded = jwt_decode(token)
      // Set current user
      return decoded
    } catch (e) {
      console.log('Error', e.response.data)
      return thunkAPI.rejectWithValue(e.response.data)
    }
  }
)

export const updateUser = createAsyncThunk(
  'auth/updateUser',
  async ({ user_id, payload }, thunkAPI) => {
    try {
      const res = await api.updateUserById(user_id, payload)

      const { token } = res.data
      // remove all old token things
      localStorage.removeItem('jwtToken')
      api.setAuthToken(false)
      // set new token
      localStorage.setItem('jwtToken', token)
      api.setAuthToken(token)
      // Decode token to get user data
      const decoded = jwt_decode(token)
      // Set current user
      return decoded
    } catch (e) {
      console.log('Error', e.response.data)
      return thunkAPI.rejectWithValue(e.response.data)
    }
  }
)

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    fetchUserByToken: (state, action) => {
      // const token = localStorage.getItem("jwtToken")
      const token = action.payload.token
      state.user = token ? jwt_decode(token) : {}
      console.log('FETCH USER', state.user)
      state.isAuthenticated = !!token
      state.loading = false
      return state
    },
    loginUser: (state, action) => {
      const token = action.payload.token
      localStorage.setItem('jwtToken', token)
      // Set token to Auth header
      api.setAuthToken(token)
      // Decode token to get user data
      const decoded = jwt_decode(token)

      state.isAuthenticated = true
      state.user = decoded
      state.errors = {}
      state.loading = false
      return state
    },
    logoutUser: (state) => {
      localStorage.removeItem('jwtToken')
      api.setAuthToken(false)
      state.isAuthenticated = false
      state.user = {}
      // reload the page to ensure access is correct
      window.location.reload()
      return state
    }
  },
  extraReducers: {
    [registerUser.fulfilled]: (state, { payload }) => {
      state.isAuthenticated = true
      state.user = payload
      state.errors = {}
      state.loading = false
      return state
    },
    [registerUser.rejected]: (state, { payload }) => {
      console.log(payload)
      state.errors = payload
      state.loading = false
      return state
    },
    [registerUser.pending]: (state) => {
      return state
    },

    [updateUser.fulfilled]: (state, { payload }) => {
      state.isAuthenticated = true
      state.user = payload
      state.errors = {}
      state.loading = false
      return state
    },
    [updateUser.rejected]: (state, { payload }) => {
      state.errors = payload
      state.loading = false
      return state
    },
    [updateUser.pending]: (state) => {
      return state
    }
  }
})

export const { logoutUser, loginUser } = authSlice.actions

// export const authSelector = (state) => state.auth;

export const authReducer = authSlice.reducer
