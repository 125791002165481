import React from 'react'
import { Row, Col } from 'react-bootstrap'
import { LoginOrAvatar } from '../../pages/Explore/Explore'

import './Header.css'

export function Header (props) {
  return (
    <div style={{ marginTop: '15px', marginBottom: '15px' }}>
      <Row>
        <Col>
          <h1 className='header-title'>{props.title}</h1>

        </Col>
        {
          props.showAvatar &&
            <Col xs={6} align='right'>
              <LoginOrAvatar {...props} />
            </Col>
        }
      </Row>
      <h2 className='header-subtitle'>{props.subtitle}</h2>
    </div>
  )
}
